import React, { useEffect } from "react"
import { Link } from "gatsby"

const DevPage = ({ data }) => {
  const sitePages = data.allSitePage.edges.map(edge => edge.node.path)

  useEffect(() => {
    const handleScroll = e => {
      const direction =
        e.deltaY > 0 ? "down" : e.deltaY < 0 ? "up" : "delta = 0"
      console.log("scrolled:", direction)
    }
    window.addEventListener("wheel", handleScroll)
    return () => {
      window.removeEventListener("wheel", handleScroll)
    }
  }, [])

  return (
    <div className="mx-auto">
      <div className="container pt-32 z-10">
        <h2 className="text-6xl font-black">Dev</h2>
      </div>
      <div className="mt-12">
        <div className="w-full min-h-screen bg-gray-200 shadow-xl">
          <div className="container mt-8 py-16">
            <h3 className=" text-lg font-bold mb-2 gatsby-image-wrapper">
              Pages
            </h3>
            <div className="flex flex-col">
              {sitePages.map(page => (
                <Link to={page} key={page} activeClassName="font-bold">
                  {page}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    allSitePage {
      edges {
        node {
          path
          id
        }
      }
    }
  }
`

export default DevPage
